@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=League+Spartan&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.leagueSpartan {
  font-family: "League Spartan", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}